.section-middle {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-video-m {
  position: absolute;
  
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.overlay-m {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

background: linear-gradient(20deg,rgba(0,0,0,1) 20%, rgba(10,0,15,0.9) 48%, rgba(9, 4, 18,0) 100%);
  z-index: -1;
}

.content-m {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.text-container-m {
  position: relative;
  top: 16px;
  margin: 44px;
  text-align: left;
}

.title-m {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

.description-m {
  color: #b3b3b3;
  font-size: 1.2rem;
  margin-top: 1rem;
  max-width: 600px;
}
