/* Center the loader container in the viewport */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Container for the loader and progress bar */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px;
  background-color: #1c1d1f; /* Dark background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

/* Progress bar background */
.progress-bar {
  width: 200px;
  height: 12px;
  background: #e0e0e0; /* Light gray background */
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

/* Actual progress indicator */
.progress {
  height: 100%;
  background: #3498db; /* Blue color */
  transition: width 0.2s ease-in-out;
}

/* Text showing loading percentage */
.progress-text {
  font-size: 14px;
  color: #ffffff; /* White text */
  font-weight: 500;
}
