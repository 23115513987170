.header{
    background-color: rgba(18, 17, 18, 0.6);
    border-bottom: 1px solid #2a2a2a;
    backdrop-filter: blur(55px);
}

/* Add these styles in your CSS file */

.nav-item {
    transition: background-color 0.2s ease;
  }
  
  .nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Change to your desired hover effect */
    border-radius: 5px;
  }
  