* {
  box-sizing: border-box;
}

.overlay1 {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for a better effect */
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the loader */
}

.loader {
  height: 80px; /* Increased size for better visibility */
  width: 80px;
  transform: rotate(-45deg);
  padding: 10px;
  animation: rotate-loader 3s infinite;
  border: 2px solid #8C52FF;
  border-radius: 12px; /* Slightly more rounded edges */
  display: flex;
  justify-content: center;
  align-items: center;
}

.segment-holder {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 50%;
}

.segment {
  position: absolute;
  background: linear-gradient(135deg, #8C52FF, #844af9); /* Gradient for a modern look */
  border-radius: 3px;
}

.one {
  bottom: 0;
  height: 0;
  width: 100%;
  animation: slide-one 1.5s infinite;
}

.two {
  left: 0;
  height: 100%;
  width: 0;
  animation: slide-two 1.5s infinite;
  animation-delay: 0.25s;
}

.three {
  right: 0;
  height: 100%;
  width: 0;
  animation: slide-two 1.5s infinite;
  animation-delay: 0.75s;
}

.four {
  top: 0;
  height: 0;
  width: 100%;
  animation: slide-one 1.5s infinite;
  animation-delay: 0.5s;
}

@keyframes slide-one {
  0% { height: 0; opacity: 1; }
  12.5% { height: 100%; opacity: 1; }
  50% { opacity: 1; }
  100% { height: 100%; opacity: 0; }
}

@keyframes slide-two {
  0% { width: 0; opacity: 1; }
  12.5% { width: 100%; opacity: 1; }
  50% { opacity: 1; }
  100% { width: 100%; opacity: 0; }
}

@keyframes rotate-loader {
  0% { transform: rotate(-45deg); }
  25% { transform: rotate(-135deg); }
  50% { transform: rotate(-225deg); }
  75% { transform: rotate(-315deg); }
  100% { transform: rotate(-405deg); }
}
