/* Custom scrollbar for webkit browsers */
.scrollbar-thin::-webkit-scrollbar {
    width: 6px; /* Thin scrollbar width */
  }
  
  /* Track (background of the scrollbar) */
  .scrollbar-thin::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray background */
  }
  
  /* Handle (scrollbar itself) */
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #b2b1b1; /* Darker gray scrollbar */
    border-radius: 10px; /* Rounded corners */
  }
  
  /* Hover effect for the scrollbar */
  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #989898; /* Darker color when hovering */
  }
  